import GlobalSettings from '@/GlobalSettings'

import { IKDataEntity } from 'metaflow-js'
import hillo from 'hillo'

const entity = {
  id: {
    type: IKDataEntity.Types.Integer,
    displayName: 'id',
    tableConfig: {
      align: 'start',
    },
  },
  lastPrintTimeStamp: {},
  createTimeStamp: {},
  printTimeStamp: {},
}
const config = {
  load: async function (filter) {
    return (await hillo.get('ZBon.php', { lang: GlobalSettings.getLang(), ...filter }))
      .content
  },
  outputMultiplePDF: async function (idStrings) {
    return (await hillo.get('ZBon.php?op=outputMultiplePDF', { idStrings }))
  },
}

export default IKDataEntity.ModelFactory(entity, config)
